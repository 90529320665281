/* --------------------------------- General -------------------------------- */

.web-body{
	background-color: #fff;
}

.web-icon-rs{
	width: 30px;
	font-size: 2em;
	color: #477ec5;
	padding: 0;
	margin: 0 20px 0 0;
}

.web-icon-rs-footer{
	font-size: 1.2em;
	color: #fff;
	padding: 0;
	margin: 0 20px 0 0;
	text-align: left;
}

.web-icon-rs-footer:hover{
	color: #477ec5;
}

.web-icon-rs:hover{
	font-size: 2em;
	color: #7abdec;
}

.web-title{
	font-family: 'Readex Pro', sans-serif;
	text-transform: uppercase;
	color: #477ec5;
	font-weight: 700;
	font-size: 2.2em;
}

.web-title-muted{
	color: #a6a6a6;
	font-weight: 300;
}

.web-text{
	font-family: 'Roboto', sans-serif;
	color: #a6a6a6;
	font-weight: normal;
	font-size: 1em;
}

.web-btn-success, .web-btn-lg-success{
	font-family: 'Readex Pro', sans-serif;
	font-weight: 700;
	font-size: 1.3em;
	color: #FFF;
	background-color: #63ad5b;
	padding: 1px 40px;
}

.web-btn-success:hover, .web-btn-lg-success:hover{
	color: #FFF;
	background-color: #4d9145;
}

.web-btn-lg-success{
	font-size: 2em;
	padding: 1px 50px;
}

/* -------------------------------------------------------------------------- */


/* --------------------------------- header --------------------------------- */

// .cirlce-header{
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	width: 130px;
// 	height: 130px;
// 	background-position: right top;
// 	background-size: contain;
// 	background-repeat: no-repeat;
// 	background-image: url('/img/circle_head.jpeg');
// 	z-index: -100;

// 	pointer-events: none;
// }

.nav-web-link{
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	color: #7abdec !important;
	border-bottom: 3px solid rgba(255, 255, 255, 0);
	padding: 0;
	padding-right: 0;
	padding-left: 0;
	margin: 10px 15px 0 15px;
	line-height: 1.1;
}

@media (min-width: 992px){
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0;
		padding-left: 0;
	}
}
.nav-web-link.active{
	border-bottom: 3px solid #42c877;
}

/* -------------------------------------------------------------------------- */


/* --------------------------------- Inicio --------------------------------- */

span.img-inicio-about{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 140%;
	min-height: 500px;
	display: block;
	// background-color: red;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url('/img/img_9.png');
	pointer-events: none;
}

@media (max-width: 992px){
	.img-inicio-about-box{
		height: 400px;
	}
}

@media (max-width: 767px){
	.img-inicio-about-box{
		height: 280px;
	}
}

.web-divider-inicio{
	width: 100%;
	height: 250px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url('/img/img_10.jpg');
}

/* -------------------------------------------------------------------------- */


/* -------------------------------- Vacantes -------------------------------- */

span.img-vacantes-header{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 130%;
	display: block;
	// background-color: red;
	background-position: right bottom;
	background-repeat: no-repeat;
	background-size: contain;
	pointer-events: none;
}

.web-divider-vacante{
	width: 100%;
	height: 350px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.web-logo-mini{
	width: 300px;
	height: 175px;
	margin: 10px;
	display: block;
	background-color: rgba(255, 255, 255, 0);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.web-rounded-vacantes{
	border-radius: 13px 13px 13px 13px;
	-moz-border-radius: 13px 13px 13px 13px;
	-webkit-border-radius: 13px 13px 13px 13px;
	overflow: hidden;
}

.web-bg-vacantes-list{
	background-color: #d1cece;
	height: 700px;
	overflow-y: auto;
	overflow-x: hidden;
}

.web-content-vacante{
	height: 652px;
	overflow-y: auto;
	overflow-x: hidden;
}

.nav-pills .nav-link {
	border-radius: 0px 0px 0px 0px;
	-moz-border-radius: 0px 0px 0px 0px;
	-webkit-border-radius: 0px 0px 0px 0px;
    background-color: #d1cece !important;
	color: #fff;
}

.nav-pills .nav-link.active {
    background-color: #538fdc !important;
}

/* -------------------------------------------------------------------------- */


/* -------------------------------- Contacto -------------------------------- */


span.imgContacto{
	display: block;
	width: 100%;
	height: 60%;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url('/img/img_7.jpg');
}

.web-form{
	display: block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #3a4147;
    background-color: #e4e7eb;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.web-form:focus {
    color: #3a4147;
    background-color: #e4e7eb;
    border-color: #a1cbef;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(52 144 220 / 25%);
}

/* -------------------------------------------------------------------------- */


/* --------------------------------- footer --------------------------------- */

.bg-footer{
	position: relative;
	background: rgba(72,103,196,1);
	background: -moz-linear-gradient(left, rgba(72,103,196,1) 0%, rgba(120,215,250,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(72,103,196,1)), color-stop(100%, rgba(120,215,250,1)));
	background: -webkit-linear-gradient(left, rgba(72,103,196,1) 0%, rgba(120,215,250,1) 100%);
	background: -o-linear-gradient(left, rgba(72,103,196,1) 0%, rgba(120,215,250,1) 100%);
	background: -ms-linear-gradient(left, rgba(72,103,196,1) 0%, rgba(120,215,250,1) 100%);
	background: linear-gradient(to right, rgba(72,103,196,1) 0%, rgba(120,215,250,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4867c4', endColorstr='#78d7fa', GradientType=1 );
}

/* -------------------------------------------------------------------------- */

.model-heigth{
	height: 500px;
	overflow-x: hidden;
	overflow-y: auto;
}

.web-load-Gh{
	position: relative;
}

.web-load-Gh span.load{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 100%;
	z-index: 100;

	background-color: rgba(0, 0, 0, 0.185);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 150px;
	background-image: url('/img/loading.svg');
}

.web-load-Gh span.load.active{
	bottom: 0;
}


/* -------------------------------- postularme ------------------------------- */

#progressbar {
    margin-bottom: 5px;
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
    margin-top: 30px
}

#progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 16.6%;
    float: left;
    position: relative;
    font-weight: 400
}

// #progressbar li svg{
// 	position: absolute;
// }

// #progressbar li svg path{
// 	fill: red;
// }

#progressbar .step0:before {
    font-family: 'Font Awesome 5 Free';
	content: fa-content($fa-var-circle);
    color: #fff
}

#progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 41px;
    display: block;
    font-size: 20px;
    background: #9dc1f0;
    border-radius: 50%;
    margin: auto;
    padding: 0px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 12px;
    background: #9dc1f0;
    position: absolute;
    left: 0;
    top: 16px;
    z-index: -1
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%
}

#progressbar li:nth-child(2):after,
#progressbar li:nth-child(3):after,
#progressbar li:nth-child(4):after,
#progressbar li:nth-child(5):after,
#progressbar li:nth-child(6):after {
    left: -50%
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #477ec5
}

#progressbar li.active:before {
    font-family: 'Font Awesome 5 Free';
	content: fa-content($fa-var-check-circle);
}
